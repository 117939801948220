import { createApp, computed } from "vue";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import en from "element-plus/es/locale/lang/en";
import App from "./App.vue";
import router from "./router/index";
import { useTagsStore } from "./store/tags";
import "element-plus/dist/index.css";
import "./assets/css/icon.css";
import wewoo from "@wewoo/error";
import { useUserInfo } from "./store/userInfo";
// 图片预览-start
import "viewerjs/dist/viewer.css"
import ViewerObj, { component as Viewer } from "v-viewer"
import { URL_COMPRESS } from "@/utils/config"
ViewerObj.setDefaults({
  url(image: any) {
    return image?.src?.replace(URL_COMPRESS, '')
  },
})
// 图片预览-end

import directives from '@/directives'
const app = createApp(App);
app.use(directives)
app.component('Viewer', Viewer)
let _origin = location.origin + "/bpi/";

app.config.globalProperties.$imgUrl = _origin;
app.use(createPinia());
app.use(router);
app.use(ElementPlus, {
  locale: en
});
let tags = useTagsStore();
let closePage = (route: any) => {
  tags.closePage({
    router,
    route
  });
};
app.config.globalProperties.$tags = tags;
app.config.globalProperties.closePage = closePage;
// 将 enum 转为 arr
const enumToArr = (ENUM: any) => {
  const keys = Object.keys(ENUM).filter((n) => Object.is(Number(n), NaN))
  const entries = Object.entries(ENUM)
  const arr = entries.flatMap(([label, value]) => (keys.includes(label) ? { label, value } : []))
  return arr
}

app.provide('enumToArr', enumToArr)

import wewooViewFilePlus from "wewoo-view-file-plus";
app.component("wewoo-view-file-plus", wewooViewFilePlus);
import wewooUploadFilePlus from "wewoo-upload-file-plus";
app.component("wewoo-upload-file-plus", wewooUploadFilePlus);
import paginationDemo from "@/components/pagination.vue";
app.component("pagination-demo", paginationDemo);

// 注册elementplus图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.directive("permission", (el, binding, vnode) => {
  if (localStorage.getItem("router")!.indexOf(binding.value) > -1) {
    //   isShow = true;
  } else {
    el.parentNode && el.parentNode.removeChild(el);
  }
});
app.use(BaiduMap, {
  ak: "yeQy3G7rojLXj0jbPUepzwXneINqjA1I"
});

import BaiduMap from "vue-baidu-map-3x";
// 添加监控
if (import.meta.env.VITE_ENVIRONMENT !== "dev") {
  let dsn = location.origin;
  app.use(wewoo, {
    dsn: dsn + "/wewoo-log-center/monitor/reportErrorData",
    sysCode: "SHOPMALL",
    appCode: 10004,
    repeatCodeError: false,
    useImgUpload: false,
    userId: useUserInfo()?.userData?.userId ?? "1701154445727",
    handleHttpStatus() {
      return true;
    }
  });
}

app.mount("#app");
