import input from "./input"

const directives = { input }

export default {
  install(app: { directive: (arg0: string, arg1: any) => void }) {
    Object.keys(directives).forEach((key) => {
      app.directive(key, directives[key])
    })
    // app.directive("input", input)
  },
}
