/*
  限制输入框只能输入数字、字母、中文等规则

  使用指令：v-input

  修饰符参数说明：
    v-input.num 只能输入数字，默认不传修饰符，会自动限制只能输入数字
    v-input.num_alp 只能输入数字和字母

*/
// 只允许输入数字
function onlyNum(el: any) {
  el.value = el.value.replace(/\D+/g, "")
}

// 只能输入正整数
function onlyIntp(el: any) {
  const value = el.value.replace(/\D+/g, "") // 去掉非数字字符
  el.value = /^[1-9][0-9]*$/.test(value) ? value : value.replace(/^0+/, "") // 确保为正整数，去掉前导零
}

// 只允许输入数字和字母
function onlyNumAlp(el: any) {
  el.value = el.value.replace(/[^A-Za-z0-9]/g, "")
}

// 只允许输入数字和小数点，n表示小数位数
function onlyFloat(el: any, n: number) {
  let value = el.value
  value = value.replace(/[^\d.]/g, "") // 允许数字和小数点
  value = value.replace(/^\./g, "") // 去掉开头的点
  value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".") // 处理多个点的情况
  if (n && Number(n) > 0) {
    const d = new Array(Number(n)).fill("\\d").join("") // 构建正则表达式
    const reg = new RegExp(`^(\\-)*(\\d+)\\.(${d}).*$`, "ig")
    value = value.replace(reg, "$1$2.$3") // 限制小数位数
  }
  if (value && !value.includes(".")) {
    value = Number(value).toString() // 去掉开头多个0
  }
  el.value = value
}
const handleLimit = (el: any, newModifier: string) => {
  // 这里扩展限制的类型
  switch (newModifier) {
    // 限制只能输入数字
    case "num":
      onlyNum(el)
      break
    // 限制只能输入数字和字母
    case "num_alp":
      onlyNumAlp(el)
      break
    default:
      break
  }
  el.dispatchEvent(new Event("input"))
}
export default {
  beforeMount(el: any, binding: any) {
    el = el.querySelector(".el-input__inner") || el.querySelector(".el-textarea__inner") || el
    const modifiers = Object.keys(binding.modifiers)
    const newModifier = modifiers[0] || "num"
    el.addEventListener("keyup", () => {
      // 这里扩展限制的类型
      switch (newModifier) {
        // 限制只能输入数字
        case "num":
          onlyNum(el)
          break
        // 限制只能输入正整数
        case "intp":
          onlyIntp(el)
          break
        // 限制只能输入数字和字母
        case "num_alp":
          onlyNumAlp(el)
          break
        case "float":
          // 限制只能输入数组和小数点（限制小数位数）
          onlyFloat(el, binding.value)
          break
        default:
          break
      }
      el.dispatchEvent(new Event("input"))
    })
  },
}
