import { defineStore } from "pinia";

interface ListItem {
  name: string;
  path: string;
  title: string;
  hideInMenu?: boolean;
}

export const useTagsStore = defineStore("tags", {
  state: () => {
    return {
      list: <ListItem[]>[],
    };
  },
  getters: {
    show: (state) => {
      return state.list.length > 0;
    },
    nameList: (state) => {
      return state.list.map((item) => item.path);
    },
  },
  actions: {
    delTagsItem(index: number) {
      this.list.splice(index, 1);
    },
    setTagsItem(data: ListItem) {
      let _CURRENTPATH_STR: any = sessionStorage.getItem("_CURRENTPATH_");
      if (_CURRENTPATH_STR) {
        let _CURRENTPATH_JSON = JSON.parse(_CURRENTPATH_STR);
        if (_CURRENTPATH_JSON.path == "/dashboard") {
          for (let i = 0; i < this.list.length; i++) {
            if (data.title == "Home") {
              if (this.list[i].path.indexOf(data.path) > -1) {
                this.list[i].path = "/dashboard";
                return false;
              }
            } else if (data.path == this.list[i].path) {
              return false;
            }
          }
        } else {
          if (this.list.length === 0) {
            this.list.push({
              name: "dashboardName",
              path: "/dashboard",
              title: "Home",
            });
          }
        }
      }

      let index = 0;
      let isClude = this.list.some((list, i) => {
        // if(list.path.split('?')[0]==data.path.split('?')[0]){
        if (list.name == data.name) {
          index = i;
          return true;
        }
      });

      // console.log(this.list,'this.list')
      // console.log(data,'data')

      if (isClude) {
        this.list.splice(index, 1, data);
      } else {
        // 如果是不显示在菜单栏中的页面，不需要添加到tags中
        if (!data.hideInMenu) {
          this.list.push(data);
        }
      }
    },
    clearTags() {
      this.list = [
        {
          name: "dashboardName",
          path: "/dashboard",
          title: "Home",
        },
      ];
    },
    closeTagsOther(data: ListItem[]) {
      if (data[0].path != "/dashboard") {
        this.list = [
          {
            name: "dashboardName",
            path: "/dashboard",
            title: "Home",
          },
          ...data,
        ];
      } else {
        this.list = data;
      }
    },
    closePage(data: any) {
      let allClass = document.querySelectorAll(".tags-li");
      allClass.forEach((list: any, index: number) => {
        if (list.className.indexOf("active") >= 0) {
          const delItem = this.list[index];

          this.delTagsItem(index);
          const item = this.list[index]
            ? this.list[index]
            : this.list[index - 1];
          if (item) {
            delItem.path === data.route.fullPath && data.router.push(item.path);
          } else {
            data.router.push("/dashboard");
          }
        }
      });
    },
    closeCurrentTag(data: any) {
      for (let i = 0, len = this.list.length; i < len; i++) {
        const item = this.list[i];
        if (item.path === data.$route.fullPath) {
          if (i < len - 1) {
            data.$router.push(this.list[i + 1].path);
          } else if (i > 0) {
            data.$router.push(this.list[i - 1].path);
          } else {
            data.$router.push("/");
          }
          this.list.splice(i, 1);
          break;
        }
      }
    },
  },
});
